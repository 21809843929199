import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Context/AuthContext";

import EmployeeBoard from "./Employee";
import Dashboard from "../../components/Dashboard/dashboard";
import DashboardService from "../../../services/dashboard.service";
import BranchService from "../../../services/branch.service";

function Index() {
  const { employee } = useAuth();

  const isAdmin = employee?.employee_role === 5;

  const [data, setData] = useState(null);
  const [branch, setBranch] = useState(employee?.employee_branch);
  const [branches, setBranches] = useState(null);
  useEffect(() => {
    const fetchBranch = async () => {
      try {
        const responseBranch = await BranchService.getAllBranchs();
        if (responseBranch.ok) {
          setBranches(responseBranch.data);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBranch();
  }, []);
  useEffect(() => {
    if (!branch) return;

    const fetchProducts = async () => {
      try {
        const response = await DashboardService.getDashboardData(branch);
        const responseBranch = await BranchService.getAllBranchs();
        if (responseBranch.ok) {
          setBranches(responseBranch.data);
        }
      
        if (response.ok) {
          const data = await response.json();
          setData(data);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProducts();
  }, [branch]); // Fetch data when `branch` changes

  return (
    <Dashboard>
      <EmployeeBoard
        data={data}
        setBranch={setBranch}
        branch={branch}
        branches={branches}
        isAdmin={isAdmin}
      />
    </Dashboard>
  );
}

export default Index;
