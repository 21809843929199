import React from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Advisory from "../../../assets/images/advisory.png";
import farmingtools from "../../../assets/images/farmingtools.png";
import seed from "../../../assets/images/seed.png";
import animalcare from "../../../assets/images/animalcare.png";
import sag from "../../../assets/images/sag.png";
import support from "../../../assets/images/support.png";

function Services() {
  return (
    <div id="services">
      <section className="py-5 py-xl-10">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h1 className="newFont d5 text-center Titel_con">Our Services</h1>
              <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-5 gx-md-5 p-5 justify-content-center">
            <div className="col-12 col-md-6 col-xl-4 overflow-hidden service_cards">
              <div className="row gy-4">
                <div className="col-12 col-lg-2 img_con">
                  <img src={seed} alt="Seed" width={40} />
                </div>
                <div className="col-12 col-lg-10">
                  <h4 className="mb-3">Seed Solutions</h4>
                  <p className="mb-3 newFont text-secondary">
                    Provide diverse range of improved vegetable and crop seeds,
                    curated for optimal yield and quality.
                  </p>
                  <div>
                    <Link className="fw-bold text-decoration-none link-primary">
                      Learn More
                      <ChevronRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 overflow-hidden service_cards">
              <div className="row gy-4">
                <div className="col-12 col-lg-2 img_con">
                  <img src={animalcare} alt="Animal Care" width={40} />
                </div>
                <div className="col-12 col-lg-10">
                  <h4 className="mb-3">Livestock Essentials</h4>
                  <p className="mb-3 newFont text-secondary">
                    Nurture your livestock with our selection of animal care
                    products and equipment. From feed to health, we provide the
                    essentials for the well-being of your animals.
                  </p>
                  <div>
                    <Link className="fw-bold text-decoration-none link-primary">
                      Learn More
                      <ChevronRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 overflow-hidden service_cards">
              <div className="row gy-4">
                <div className="col-12 col-lg-2 img_con">
                  <img src={farmingtools} alt="Farming Tools" width={40} />
                </div>
                <div className="col-12 col-lg-10">
                  <h4 className="mb-3">Farming Tools & Equipment</h4>
                  <p className="mb-3 newFont text-secondary">
                    Elevate your farming experience with our high-quality tools
                    and equipment. From plowing to harvesting, we've got the
                    tools you need for efficient and effective farming.
                  </p>
                  <div>
                    <Link className="fw-bold text-decoration-none link-primary">
                      Learn More
                      <ChevronRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 overflow-hidden service_cards">
              <div className="row gy-4">
                <div className="col-12 col-lg-2 img_con">
                  <img src={sag} alt="Agrochemicals" width={40} />
                </div>
                <div className="col-12 col-lg-10">
                  <h4 className="mb-3">Agrochemicals & Plant Nutrients</h4>
                  <p className="mb-3 newFont text-secondary">
                    Protect your crops with effective plant nutrients and
                    agrochemicals. Ensure an abundant harvest while prioritizing
                    the health of your crops and environment.
                  </p>
                  <div>
                    <Link className="fw-bold text-decoration-none link-primary">
                      Learn More
                      <ChevronRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 overflow-hidden service_cards">
              <div className="row gy-4">
                <div className="col-12 col-lg-2 img_con">
                  <img src={Advisory} alt="Advisory" width={40} />
                </div>
                <div className="col-12 col-lg-10">
                  <h4 className="mb-3">Advisory & Training Services</h4>
                  <p className="mb-3 newFont text-secondary">
                    Our advisory and training services provide the knowledge and
                    skills you need for optimal farming practices.
                  </p>
                  <div>
                    <Link className="fw-bold text-decoration-none link-primary">
                      Learn More
                      <ChevronRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 overflow-hidden service_cards">
              <div className="row gy-4">
                <div className="col-12 col-lg-2 img_con">
                  <img src={support} alt="Support" width={40} />
                </div>
                <div className="col-12 col-lg-10">
                  <h4 className="mb-3">Support Services</h4>
                  <p className="mb-3 newFont text-secondary">
                    We provide dedicated support services to assist you at every
                    step. Whether you need help with product usage or farm
                    management, our team is here to ensure your agricultural
                    success.
                  </p>
                  <div>
                    <Link className="fw-bold text-decoration-none link-primary">
                      Learn More
                      <ChevronRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
