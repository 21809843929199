import React from "react";

import { useAuth } from "../../../Context/AuthContext";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import StoreIcon from "@mui/icons-material/Store";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ReportIcon from "@mui/icons-material/Report";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import BackupIcon from "@mui/icons-material/Backup";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FeedbackIcon from "@mui/icons-material/Feedback";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PublicIcon from "@mui/icons-material/Public";
import { useLocation } from "react-router-dom";
export const AdminItems = [
  {
    icon: <DashboardOutlinedIcon className="sidebar-icon" />,
    text: "Dashboard",
    link: "/dashboard",
    v: false,
  },
  {
    icon: <HomeOutlinedIcon className="sidebar-icon" />,
    text: "Home",
    link: "/",
    v: false,
  },
  {
    icon: <PublicIcon className="sidebar-icon" />,
    text: "Online Employee",
    link: "/dashboard/employee/online",
    v: false,
  },
  {
    icon: <ArticleOutlinedIcon className="sidebar-icon" />,
    text: "Documentation",
    link: "/",
    v: false,
  },
  {
    icon: <GroupsIcon className="sidebar-icon" />,
    text: "Employees",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Add Employee", link: "/dashboard/admin/add-employee" },
      { text: "Employees", link: "/dashboard/admin/employees" },
      {
        text: "Employees Activity",
        link: "/dashboard/admin/employees/all-activity",
      },
      {
        text: "Employees Report",
        link: "/dashboard/admin/employees/all-report",
      },
      {
        text: "Employees OTP",
        link: "/dashboard/admin/otps",
      },
    ],
  },
  {
    icon: <StoreIcon className="sidebar-icon" />,
    text: "Showroom",
    v: false,
    dropdown: [
      {
        text: "Agro",
        v: false,
        submenu: [
          { text: "Customers", link: "/dashboard/showroom/agro/customer" },
          { text: "Products", link: "/dashboard/showroom/agro/product" },
          {
            text: "Clone Products",
            link: "/dashboard/showroom/agro/clone/product",
          },
          {
            text: "Pending Products",
            link: "/dashboard/showroom/agro/pending-products",
          },
          { text: "Truth Track", link: "/dashboard/showroom/agro/truth-track" },
          { text: "Reports", link: "/dashboard/showroom/agro/report" },
          {
            text: "Sold Products",
            link: "/dashboard/showroom/agro/sold-products",
          },
          {
            text: "Expired Products",
            link: "/dashboard/showroom/agro/expired-products",
          },
          {
            text: "SQ Products",
            link: "/dashboard/showroom/agro/small-quantity-products",
          },
        ],
      },
      {
        text: "Vetrinary",
        v: false,
        submenu: [
          { text: "Customers", link: "/dashboard/showroom/vet/customer" },
          { text: "Products", link: "/dashboard/showroom/vet/product" },
          {
            text: "Clone Products",
            link: "/dashboard/showroom/vet/clone/product",
          },
          {
            text: "Pending Products",
            link: "/dashboard/showroom/vet/pending-products",
          },
          { text: "Truth Track", link: "/dashboard/showroom/vet/truth-track" },
          { text: "Reports", link: "/dashboard/showroom/vet/report" },
          {
            text: "Sold Products",
            link: "/dashboard/showroom/vet/sold-products",
          },
          {
            text: "Expired Products",
            link: "/dashboard/showroom/vet/expired-products",
          },
          {
            text: "SQ Products",
            link: "/dashboard/showroom/vet/small-quantity-products",
          },
        ],
      },
    ],
  },
  {
    icon: <StorefrontIcon className="sidebar-icon" />,
    text: "Stores",
    dropdown: [
      {
        text: "Agro Input",
        v: false,
        submenu: [
          {
            text: "Add New Product",
            link: "/dashboard/store/agro/add-product",
          },
          {
            text: "Available Products",
            link: "/dashboard/store/agro/available-products",
          },
          {
            text: "Outed Products",
            link: "/dashboard/store/agro/outed-products",
          },
          {
            text: "Clone Products",
            link: "/dashboard/store/agro/clone-products",
          },
          {
            text: "Expired Products",
            link: "/dashboard/store/agro/expired-products",
          },
          { text: "Truth Track", link: "/dashboard/store/agro/truth-track" },
          {
            text: "Small Q Products",
            link: "/dashboard/store/agro/small-quantity-products",
          },
        ],
      },
      {
        text: "Vetrinary Input",
        v: false,

        submenu: [
          { text: "Add New Product", link: "/dashboard/store/vet/add-product" },
          {
            text: "Available Products",
            link: "/dashboard/store/vet/available-products",
          },
          {
            text: "Outed Products",
            link: "/dashboard/store/vet/outed-products",
          },
          {
            text: "Clone Products",
            link: "/dashboard/store/vet/clone-products",
          },
          {
            text: "Expired Products",
            link: "/dashboard/store/vet/expired-products",
          },
          { text: "Truth Track", link: "/dashboard/store/vet/truth-track" },
          {
            text: "Small Q Products",
            link: "/dashboard/store/vet/small-quantity-products",
          },
        ],
      },
    ],
  },
  {
    icon: <NotificationImportantIcon className="sidebar-icon" />,
    text: "Reminders",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/agro/employee/reminder" },
      { text: "Vetrinary", link: "/dashboard/vet/employee/reminder" },
    ],
  },
  {
    icon: <AddBusinessIcon className="sidebar-icon" />,
    text: "Branch",
    link: "/dashboard/admin/branch",
    v: false,
  },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Credits",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/showroom/agro/customer/credits" },
      { text: "Vetrinary", link: "/dashboard/showroom/vet/customer/credits" },
    ],
  },
  {
    icon: <ShoppingCartOutlinedIcon className="sidebar-icon" />,
    text: "Orders",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/agro/customer/orders" },
      { text: "Vetrinary", link: "/dashboard/vet/customer/orders" },
    ],
  },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Petty Payments",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/agro/petty-payment" },
      { text: "Vetrinary", link: "/dashboard/vet/petty-payment" },
    ],
  },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Confirmations",
    link: "/dashboard/showroom/agro/customer/credits",
    v: false,
  },
  {
    icon: <HelpOutlineIcon className="sidebar-icon" />,
    text: "Help",
    link: "/dashboard/admin/help",
    v: false,
  },
  {
    icon: <QuestionAnswerIcon className="sidebar-icon" />,
    text: "Agri Mentor AI",
    link: "/dashboard/ai-assistant/",
    v: false,
  },
  {
    icon: <FeedbackIcon className="sidebar-icon" />,
    text: "Feedbacks",
    link: "/dashboard/employee/feedbacks",
    v: false,
  },
  {
    icon: <BackupIcon className="sidebar-icon" />,
    text: "Backup",
    link: "/dashboard/backup",
    v: false,
  },
  // {
  //   icon: <QrCodeIcon className="sidebar-icon" />,
  //   text: "Codes",
  //   link: "/dashboard/product/barcode",
  //   v: false,
  // },
  {
    icon: <QrCodeIcon className="sidebar-icon" />,
    text: "Codes",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/product/barcode/ag" },
      { text: "Vetrinary", link: "/dashboard/product/barcode/vt" },
    ],
  },
  {
    icon: <ReportIcon className="sidebar-icon" />,
    text: "Report",
    link: "/dashboard/my-reports",
    v: false,
    // badge: totalReportRecords > 1 ? `+${totalReportRecords - 1}` : "",
  },
];

export const EmployeeAgronomistItems = [
  {
    icon: <DashboardOutlinedIcon className="sidebar-icon" />,
    text: "Dashboard",
    v: false,
    link: "/dashboard",
  },
  {
    icon: <HomeOutlinedIcon className="sidebar-icon" />,
    text: "Home",
    link: "/",
    v: false,
  },
  {
    icon: <PublicIcon className="sidebar-icon" />,
    text: "Online Employee",
    link: "/dashboard/employee/online",
    v: false,
  },
  {
    icon: <Inventory2OutlinedIcon className="sidebar-icon" />,
    text: "Products",
    v: false,
    SubMenu: false,
    dropdown: [
      { text: "Products", link: "/dashboard/showroom/agro/product" },
      {
        text: "Pending Products",
        link: "/dashboard/showroom/agro/pending-products",
      },
      {
        text: "Clone Products",
        link: "/dashboard/showroom/agro/clone/product",
      },
      {
        text: "Sold Products",
        link: "/dashboard/showroom/agro/sold-products",
      },
      {
        text: "Expired Products",
        link: "/dashboard/showroom/agro/expired-products",
      },
      {
        text: "SQ Products",
        link: "/dashboard/showroom/agro/small-quantity-products",
      },
    ],
  },
  {
    icon: <PeopleAltOutlinedIcon className="sidebar-icon" />,
    text: "Customers",
    link: "/dashboard/showroom/agro/customer",
    v: false,
  },
  {
    icon: <InsightsOutlinedIcon className="sidebar-icon" />,
    text: "Truth Track",
    link: "/dashboard/showroom/agro/truth-track",
    v: false,
  },
  {
    icon: <MonetizationOnOutlinedIcon className="sidebar-icon" />,
    text: "Reports",
    link: "/dashboard/showroom/agro/report",
    v: false,
  },

  {
    icon: <NotificationImportantIcon className="sidebar-icon" />,
    text: "Reminders",
    link: "/dashboard/agro/employee/reminder",
    v: false,
  },
  // {
  //   icon: <AddBusinessIcon className="sidebar-icon" />,
  //   text: "Branchs",
  //   link: "/dashboard/admin/branch",
  // },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Credits",
    link: "/dashboard/showroom/agro/customer/credits",
    v: false,
  },
  {
    icon: <ShoppingCartOutlinedIcon className="sidebar-icon" />,
    text: "Orders",
    SubMenu: false,
    link: "/dashboard/agro/customer/orders",
    v: false,
  },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Petty Payments",
    link: "/dashboard/agro/petty-payment",
    v: false,
  },
  {
    icon: <HelpOutlineIcon className="sidebar-icon" />,
    text: "Help",
    link: "/dashboard/employee/help",
    v: false,
  },
  {
    icon: <QuestionAnswerIcon className="sidebar-icon" />,
    text: "Agri Mentor AI",
    link: "/dashboard/ai-assistant/",
    v: false,
  },
  {
    icon: <FeedbackIcon className="sidebar-icon" />,
    text: "Feedbacks",
    link: "/dashboard/employee/feedbacks",
    v: false,
  },
  {
    icon: <BackupIcon className="sidebar-icon" />,
    text: "Backup",
    link: "/dashboard/backup",
    v: false,
  },
  {
    icon: <QrCodeIcon className="sidebar-icon" />,
    text: "Codes",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/product/barcode/ag" },
      { text: "Vetrinary", link: "/dashboard/product/barcode/vt" },
    ],
  },
  {
    icon: <ReportIcon className="sidebar-icon" />,
    text: "My Report",
    link: "/dashboard/employee/agro/my-reports",
    v: false,
  },
  {
    icon: <ArticleOutlinedIcon className="sidebar-icon" />,
    text: "Documentation",
    v: false,
  },
];

export const EmployeeVeterinarianItems = [
  {
    icon: <DashboardOutlinedIcon className="sidebar-icon" />,
    text: "Dashboard",
    link: "/dashboard",

    v: false,
  },
  {
    icon: <HomeOutlinedIcon className="sidebar-icon" />,
    text: "Home",
    link: "/",
    v: false,
  },
  {
    icon: <PublicIcon className="sidebar-icon" />,
    text: "Online Employee",
    link: "/dashboard/employee/online",
    v: false,
  },
  {
    icon: <Inventory2OutlinedIcon className="sidebar-icon" />,
    text: "Products",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Products", link: "/dashboard/showroom/vet/product" },
      {
        text: "Pending Products",
        link: "/dashboard/showroom/vet/pending-products",
      },
      {
        text: "Clone Products",
        link: "/dashboard/showroom/vet/clone/product",
      },
      {
        text: "Sold Products",
        link: "/dashboard/showroom/vet/sold-products",
      },
      {
        text: "Expired Products",
        link: "/dashboard/showroom/vet/expired-products",
      },
      {
        text: "SQ Products",
        link: "/dashboard/showroom/vet/small-quantity-products",
      },
    ],
  },
  {
    icon: <PeopleAltOutlinedIcon className="sidebar-icon" />,
    text: "Customers",
    link: "/dashboard/showroom/vet/customer",
    v: false,
  },
  {
    icon: <InsightsOutlinedIcon className="sidebar-icon" />,
    text: "Truth Track",
    link: "/dashboard/showroom/vet/truth-track",
    v: false,
  },
  {
    icon: <MonetizationOnOutlinedIcon className="sidebar-icon" />,
    text: "Reports",
    link: "/dashboard/showroom/vet/report",
    v: false,
  },

  {
    icon: <NotificationImportantIcon className="sidebar-icon" />,
    text: "Reminders",
    link: "/dashboard/vet/employee/reminder",
    v: false,
  },
  // {
  //   icon: <AddBusinessIcon className="sidebar-icon" />,
  //   text: "Branchs",
  //   link: "/dashboard/admin/branch",
  // },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Credits",
    link: "/dashboard/showroom/vet/customer/credits",
    v: false,
  },
  {
    icon: <ShoppingCartOutlinedIcon className="sidebar-icon" />,
    text: "Orders",
    SubMenu: false,
    link: "/dashboard/vet/customer/orders",
    v: false,
  },
  {
    icon: <CreditCardIcon className="sidebar-icon" />,
    text: "Petty Payments",
    link: "/dashboard/vet/petty-payment",
    v: false,
  },
  {
    icon: <HelpOutlineIcon className="sidebar-icon" />,
    text: "Help",
    link: "/dashboard/employee/help",
    v: false,
  },
  {
    icon: <QuestionAnswerIcon className="sidebar-icon" />,
    text: "Agri Mentor AI",
    link: "/dashboard/ai-assistant/",
    v: false,
  },
  {
    icon: <FeedbackIcon className="sidebar-icon" />,
    text: "Feedbacks",
    link: "/dashboard/employee/feedbacks",
    v: false,
  },
  {
    icon: <BackupIcon className="sidebar-icon" />,
    text: "Backup",
    link: "/dashboard/backup",
    v: false,
  },
  {
    icon: <QrCodeIcon className="sidebar-icon" />,
    text: "Codes",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/product/barcode/ag" },
      { text: "Vetrinary", link: "/dashboard/product/barcode/vt" },
    ],
  },
  {
    icon: <ReportIcon className="sidebar-icon" />,
    text: "My Report",
    link: "/dashboard/employee/vet/my-reports",
    v: false,
  },
  {
    icon: <ArticleOutlinedIcon className="sidebar-icon" />,
    text: "Documentation",
    v: false,
  },
];

export const StoreManagerItems = [
  {
    icon: <DashboardOutlinedIcon className="sidebar-icon" />,
    text: "Dashboard",
    link: "/dashboard",

    v: false,
  },
  {
    icon: <HomeOutlinedIcon className="sidebar-icon" />,
    text: "Home",
    link: "/",
    v: false,
  },
  {
    icon: <PublicIcon className="sidebar-icon" />,
    text: "Online Employee",
    link: "/dashboard/employee/online",
    v: false,
  },
  {
    icon: <Inventory2OutlinedIcon className="sidebar-icon" />,
    text: "Agro Products",
    v: false,
    dropdown: [
      {
        text: "Add New Product",
        link: "/dashboard/store/agro/add-product",
      },
      {
        text: "Available Products",
        link: "/dashboard/store/agro/available-products",
      },
      {
        text: "Outed Products",
        link: "/dashboard/store/agro/outed-products",
      },
      {
        text: "Clone Products",
        link: "/dashboard/store/agro/clone-products",
      },
      {
        text: "Expired Products",
        link: "/dashboard/store/agro/expired-products",
      },
      { text: "Truth Track", link: "/dashboard/store/agro/truth-track" },
      {
        text: "Small Q Products",
        link: "/dashboard/store/agro/small-quantity-products",
      },
    ],
  },

  {
    icon: <Inventory2OutlinedIcon className="sidebar-icon" />,
    text: "Vet Products",
    v: false,
    dropdown: [
      { text: "Add New Product", link: "/dashboard/store/vet/add-product" },
      {
        text: "Available Products",
        link: "/dashboard/store/vet/available-products",
      },
      {
        text: "Outed Products",
        link: "/dashboard/store/vet/outed-products",
      },
      {
        text: "Clone Products",
        link: "/dashboard/store/vet/clone-products",
      },
      {
        text: "Expired Products",
        link: "/dashboard/store/vet/expired-products",
      },
      { text: "Truth Track", link: "/dashboard/store/vet/truth-track" },
      {
        text: "Small Q Products",
        link: "/dashboard/store/vet/small-quantity-products",
      },
    ],
  },

  // {
  //   icon: <AddBusinessIcon className="sidebar-icon" />,
  //   text: "Branchs",
  //   link: "/dashboard/admin/branch",
  // },

  {
    icon: <HelpOutlineIcon className="sidebar-icon" />,
    text: "Help",
    link: "/dashboard/employee/help",
    v: false,
  },
  {
    icon: <QuestionAnswerIcon className="sidebar-icon" />,
    text: "Agri Mentor AI",
    link: "/dashboard/ai-assistant/",
    v: false,
  },
  {
    icon: <FeedbackIcon className="sidebar-icon" />,
    text: "Feedbacks",
    link: "/dashboard/employee/feedbacks",
    v: false,
  },
  {
    icon: <BackupIcon className="sidebar-icon" />,
    text: "Backup",
    link: "/dashboard/backup",
    v: false,
  },
  {
    icon: <QrCodeIcon className="sidebar-icon" />,
    text: "Codes",
    SubMenu: false,
    v: false,
    dropdown: [
      { text: "Agro", link: "/dashboard/product/barcode/ag" },
      { text: "Vetrinary", link: "/dashboard/product/barcode/vt" },
    ],
  },
  {
    icon: <ArticleOutlinedIcon className="sidebar-icon" />,
    text: "Documentation",
    v: false,
  },
];

// Adjust the import path accordingly

const useSidebarItems = () => {
  const { employee } = useAuth();
  const location = useLocation();
  let SidebarItem = [];

  const updateDropdownVisibility = (items) => {
    return items.map((item) => {
      if (item.dropdown) {
        item.v = item.dropdown.some((subItem) => {
          if (subItem.submenu) {
            return subItem.submenu.some(
              (submenuItem) => submenuItem.link === location.pathname
            );
          }
          return subItem.link === location.pathname;
        });
        item.dropdown = updateDropdownVisibility(item.dropdown);
      }
      return item;
    });
  };

  if (employee?.employee_role === 5) {
    SidebarItem = updateDropdownVisibility(AdminItems);
  } else if (employee?.employee_role === 1) {
    SidebarItem = updateDropdownVisibility(EmployeeAgronomistItems);
  } else if (employee?.employee_role === 2) {
    SidebarItem = updateDropdownVisibility(EmployeeVeterinarianItems);
  } else if (employee?.employee_role === 3) {
    SidebarItem = updateDropdownVisibility(StoreManagerItems);
  }

  return SidebarItem;
};

export default useSidebarItems;
