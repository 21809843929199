import React from "react";
import useEmployeeOnlineStatus from "../../components/Employee/useEmployeeOnlineStatus";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEmployeeStatus } from "../../../Context/EmployeeStatusProvider";

function Online() {
  // const { onlineEmployees: employees } = useEmployeeOnlineStatus();
  const { onlineEmployees: employees, status } = useEmployeeStatus();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <Card
      sx={{
        width: "49%",
        borderRadius: 2,
        backgroundColor: "#171E26",
        padding: 1.2,
        color: "white",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ marginBottom: 2, fontWeight: "bold", fontSize: 18 }}
        >
          ({employees?.length}) Active Employees
        </Typography>
        <Box sx={{ maxHeight: 300, overflowY: "auto", overflowX: "hidden" }}>
          {employees && employees.length > 0 ? (
            <Grid container spacing={2}>
              {employees.map((employee) => (
                <Grid item xs={12} key={employee.employee_id}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                      bgcolor: "#252c33",
                      borderRadius: 2,
                    }}
                  >
                    {employee.employee_profile ? (
                      <Tooltip
                        title={`${employee.employee_first_name} from ${employee.employee_branch} Branch is Online`}
                        placement="left-start"
                      >
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            sx={{ width: 50, height: 50 }}
                            alt="Profile pic"
                            src={`data:image/webp;base64,${employee.employee_profile}`}
                          />
                        </StyledBadge>
                      </Tooltip>
                    ) : (
                      <Avatar
                        sx={{ bgcolor: "blueviolet", width: 50, height: 50 }}
                      >
                        {employee.employee_first_name.charAt(0).toUpperCase()}
                      </Avatar>
                    )}

                    <Box sx={{ marginLeft: 2, textAlign: "start" }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {employee.employee_first_name}{" "}
                        {employee.employee_last_name}
                      </Typography>
                      <Typography variant="body2" color="gray">
                        {employee.employee_email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No employees are online.
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default Online;
