import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const RevenueBarChart = ({ pastRevenue }) => {
  // Extract dates and revenue values
  const labels = pastRevenue?.map((item) =>
    new Date(item.date).toLocaleDateString()
  );
  console.log(pastRevenue);
  const dataValues = pastRevenue.map((item) => parseFloat(item.revenue));

  const data = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: dataValues,
        backgroundColor: "rgba(9, 255, 0, 0.81)",
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 5,
        // boarderRadius: 19,
      },
    ],
  };

  const options = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default RevenueBarChart;
