import React from "react";
import footerlogo from "../../../assets/images/footer-logo.png";
import mission from "../../../assets/images/10041779.png";
import vision from "../../../assets/images/10258036.png";
const About = () => {
  return (
    <div id="about" className="mb-5 mt-5 section">
      <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6 col-xl-5 aboutusimgconn ">
              <img
                className="img-fluid rounded aboutusimg"
                src={footerlogo}
                alt=""
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-7">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h1 className="mb-4 newFont d5 text-start Titel_con text-center">
                    About us
                  </h1>
                  <p className="lead  fs-4 text-secondary mb-3">
                    Our primary focus is on enhancing production and
                    productivity.
                  </p>
                  <p className="mb-5 new-font">
                    The establishment of Buee and Kella Agricultural Input
                    Provision and Service Centers represents a pivotal step in
                    the modernization of agriculture, facilitated by the
                    Ethiopian Agricultural Transformation Agency. Our core
                    objective is to bolster the production and productivity of
                    smallholder farmers by ensuring seamless access to essential
                    agricultural inputs. We are committed to offering these
                    inputs at affordable prices, maintaining uncompromising
                    standards of quality and quantity, and delivering them
                    punctually. Moreover, our dedicated team provides
                    professional guidance and advice to farmers, empowering them
                    to optimize their farming practices and achieve sustainable
                    growth.
                  </p>
                  <div className="row gy-4 gy-md-0 gx-xxl-5X">
                    <div className="col-12 col-md-6 vi_card">
                      <div className="d-flex">
                        <div className="me-4 text-success">
                          <img src={vision} alt={"vision icon"} width={50} />
                        </div>
                        <div>
                          <h4 className="mb-3">Vision</h4>
                          <p className="text-secondary newFont mb-0">
                            Our mission is to modernize agriculture in Ethiopia
                            by collaborating with the Ethiopian Agricultural
                            Transformation Agency and other stakeholders. We are
                            committed to providing smallholder farmers with
                            high-quality inputs at fair prices, in the necessary
                            quantities, delivered on time, and accompanied by
                            professional guidance to enhance their productivity.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 vi_card">
                      <div className="d-flex">
                        <div className="me-4 text-success">
                          <img src={mission} alt="mission icon" width={50} />
                        </div>
                        <div>
                          <h4 className="mb-3">Mission</h4>
                          <p className="text-secondary newFont  mb-0">
                            Our mission is to modernize agriculture in
                            partnership with the Ethiopian Agricultural
                            Transformation Agency and with other stakeholders,
                            by providing high-quality inputs with fair prices,
                            in the required amount, in a timely manner, and with
                            professional guidance to enhance the productivity of
                            smallholder farmers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
