import React, { useEffect, useState } from "react";
import Center1 from "../../../assets/images/center12.png";
import Center2 from "../../../assets/images/kella_aosscenter1.jpg";
import Center3 from "../../../assets/images/kella_aosscenter.jpg";

import Carousel from "react-bootstrap/Carousel";

import "bootstrap/dist/css/bootstrap.min.css";
function DarkVariantExample() {
  const [textIndex, setTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const textArray = ["Your Trusted Partner in Agriculture"];

  const textArray1 = "Quality Products and Expert Support";
  const textArray2 = "Empowering Farmers, Ensuring Quality.";
  const textArray3 = "Growing Together, Harvesting Success.";

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
      setDisplayedText("");
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const currentText = textArray[textIndex];
  //   let charIndex = 0;
  //   const typingInterval = setInterval(() => {
  //     setDisplayedText(currentText.slice(0, charIndex + 1));
  //     charIndex++;
  //     if (charIndex === currentText.length) {
  //       clearInterval(typingInterval);
  //     }
  //   }, 150); // Typing speed

  //   return () => clearInterval(typingInterval);
  // }, [textIndex]);

  return (
    <div
      id="Home"
      className="black-"
      style={{ display: "block", width: "100%" }}
    >
      <Carousel data-bs-theme="light">
        <Carousel.Item interval={2900}>
          <img className="d-block w-100 fimg" src={Center1} />
          <Carousel.Caption className="heroh1s">
            <h1 className="heroh1s text-center d-sm-none d-md-none d-lg-block">
              {textArray1}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2900}>
          <img className="d-block w-100 fimg" src={Center3} />
          <Carousel.Caption className="heroh1s">
            <h1 className="heroh1s text-center d-sm-none d-md-none d-lg-block">
              {textArray2}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2900}>
          <img className="d-block w-100 fimg" src={Center2} />
          <Carousel.Caption className="heroh1s">
            <h1 className="heroh1s text-center d-sm-none d-md-none d-lg-block">
              {textArray3}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <h1 className="heroh1sMobile d-lg-none  text-center">
        Buee Agricultural One Stop Shop Center
      </h1>
    </div>
  );
}

export default DarkVariantExample;
