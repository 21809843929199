import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import RevenueBarChart from "./RevenueBarChart";
import Online from "./Online";
import ProductTypePieChart from "../../components/Charts/PieCharts/ProductTypePieChart";
import { Link } from "react-router-dom";
import { useBranchs } from "../../components/APIs/FetchAPIs";

const EmployeeBoard = ({ data, setBranch, branch, isAdmin }) => {
  const dataOptions = [
    { key: "vet", label: "Vet" },
    { key: "agro", label: "Agro" },
    { key: "agroStore", label: "Agro Store" },
    { key: "animalFeed", label: "Animal Feed" },
    { key: "vetStore", label: "Vet Store" },
    { key: "animalFeedStore", label: "Animal Feed Store" },
  ];
  const { branchs } = useBranchs();

  const availableDataOptions = dataOptions.filter(
    (option) => data?.[option.key] && data[option.key]?.products?.length > 0
  );
  const initialKey =
    availableDataOptions.length > 0 ? availableDataOptions[0].key : "";

  const [search, setSearch] = useState("");
  const [selectedKey, setSelectedKey] = useState(initialKey);
  const [selectedData, setSelectedData] = useState(data?.[initialKey]);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const availableOptions = dataOptions.filter(
      (option) => data?.[option.key] && data[option.key]?.products?.length > 0
    );

    if (availableOptions.length > 0) {
      setSelectedKey(availableOptions[0].key);
      setSelectedData(data[availableOptions[0].key]);
    }
  }, [data]);

  const handleChange = (event) => {
    const selectedKey = event.target.value;
    setSelectedKey(selectedKey);
    setSelectedData(data?.[selectedKey] || null);
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  // Update products when selectedData changes
  useEffect(() => {
    if (selectedData) {
      setProducts(selectedData?.products || []);
    }
  }, [selectedData]);

  const filteredProducts = products
    .filter((product) =>
      product.product_name.toLowerCase().includes(search.toLowerCase())
    )
    .slice(0, 3);

  return (
    <div className="p-4">
      <div className="row g-4">
        <div className="col-sm-6 col-xl-4">
          <div className="ovr-1 rounded d-flex align-items-center justify-content-between p-4">
            <i className=" rounded shadow-3  p-4">
              <StackedLineChartIcon className="newsalesicon" />
            </i>
            <div className="ms-3 text-end">
              <p className="mb-2 text-white">Today's Sales Balance</p>
              <h5 className="mb-0 text-white">
                ETB{" "}
                {selectedData &&
                  Number(selectedData?.todayRevenue).toLocaleString()}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="ovr-9 rounded d-flex align-items-center justify-content-between p-4">
            <i className=" rounded shadow-3  p-4">
              <MonetizationOnIcon className="newsalesicon" />
            </i>
            <div className="ms-3 text-end">
              <p className="mb-2 text-white">Total Stock Balance</p>
              <h5 className="mb-0 text-white">
                ETB{" "}
                {selectedData &&
                  Number(selectedData?.totalStokeBalance).toLocaleString()}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4">
          <div className="ovr-7 rounded d-flex align-items-center justify-content-between p-4">
            <i className=" rounded shadow-3  p-4">
              <LeaderboardIcon className="newsalesicon" />
            </i>
            <div className="ms-3 text-end">
              <p className="mb-2 font-bold text-white">
                Total Store Outed Product
              </p>
              <h5 className="mb-0 text-white">
                ETB{" "}
                {selectedData &&
                  Number(selectedData?.addedProductBalance).toLocaleString()}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid  pt-4 px-1">
        <div className="row g-4">
          <div className="col-sm-12 col-xl-6">
            <div
              style={{ backgroundColor: "#171E26" }}
              className=" text-center rounded p-4"
            >
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0 text-gray  ">Past 15 days Revenue</h6>
                <Link className="text-gray">Show All</Link>
              </div>
              {selectedData && (
                <RevenueBarChart pastRevenue={selectedData?.pastRevenue} />
              )}
            </div>
          </div>
          <div className="col-sm-12 col-xl-6">
            <div
              style={{ backgroundColor: "#171E26" }}
              className=" text-center rounded p-4"
            >
              <div className="d-flex align-items-center justify-content-between ">
                <h6 className="mb-0 text-gray">Products By Type</h6>
                <Link className="text-gray">Show All</Link>
              </div>
              {selectedData && (
                <ProductTypePieChart
                  products={selectedData?.products}
                  displayLabels={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Box display="flex" flexDirection="row" gap={2} sx={{ marginTop: 5 }}>
        <Online />
        <Card
          sx={{
            backgroundColor: "#171E26",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 2, fontWeight: "bold", fontSize: 18 }}
                textAlign="start"
                gutterBottom
              >
                Check Product Availability
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <FormControl sx={{ width: 200 }} variant="standard">
                  <InputLabel sx={{ color: "white" }}>
                    Select Service Type
                  </InputLabel>
                  <Select
                    value={selectedKey}
                    onChange={handleChange}
                    label="Select Service Type"
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                      backgroundColor: "#5b6c7c",
                    }}
                  >
                    {availableDataOptions.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography
                    variant="body"
                    sx={{ fontSize: 13, color: "gray" }}
                  >
                    Select a service type to view data
                  </Typography>
                </FormControl>
                <FormControl sx={{ width: 200 }} variant="standard">
                  <InputLabel sx={{ color: "white" }}>Select Branch</InputLabel>
                  <Select
                    value={branch}
                    onChange={handleBranchChange}
                    disabled={!isAdmin}
                    label="Select Branch"
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                      backgroundColor: "#5b6c7c",
                    }}
                  >
                    {branchs &&
                      branchs.map((branch) => (
                        <MenuItem key={branch.branch_id} value={branch.city}>
                          {branch.city}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography
                    variant="body"
                    sx={{ fontSize: 13, color: "gray" }}
                  >
                    {!isAdmin
                      ? "You can Only access your data"
                      : "Select a city to view data"}
                  </Typography>
                </FormControl>
              </Box>
            </Box>

            <TextField
              label="Check Product Availability"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{ input: { color: "white" }, label: { color: "gray" } }}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search ? (
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: "#171e26" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "white" }}>
                        Product Name
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>Type</TableCell>
                      <TableCell sx={{ color: "white" }}>Price</TableCell>
                      <TableCell sx={{ color: "white" }}>Stock</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts.map((product) => (
                      <TableRow key={product.product_id}>
                        <TableCell sx={{ color: "white" }}>
                          {product.product_name}
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          {product.product_type}
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          ETB {product.product_unit_price}
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          {product.product_quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" sx={{ color: "gray", mt: 5 }}>
                Search Product To check Your stoke
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default EmployeeBoard;
