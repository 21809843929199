// frontendService.js
const api_url = process.env.REACT_APP_API_URL;




const getDashboardData = async (branch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/dashboard/${branch}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get dashboard data: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error("Error getting get dashboard data:", error.message);
    throw error;
  }
};

const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};



const DashboardService = {
  getDashboardData,
};

export default DashboardService;
