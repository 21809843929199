import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

function ProductTypePieChart({ products }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!products || products.length === 0) return;

    const productTypeData = products.reduce((acc, product) => {
      const type = product.product_type || product.agro_chemical_type;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(productTypeData);
    const data = Object.values(productTypeData);

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById("ProductTypePieChart").getContext("2d");

    chartRef.current = new Chart(ctx, {
      type: "pie",
      data: {
        labels, // Labels are still defined, just hidden initially
        datasets: [
          {
            data,
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF8A80",
              "#81D4FA",
              "#FFD54F",
              "#4DB6AC",
              "#B39DDB",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false, // Hide legend labels at the start
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem) {
                const chartData = tooltipItem.chart.data;
                const index = tooltipItem.dataIndex;
                const label = chartData.labels[index] || "Unknown";
                const value = chartData.datasets[0].data[index];
                return `${label}: ${value}`;
              },
            },
          },
        },
      },
    });
  }, [products]);

  return <canvas id="ProductTypePieChart"></canvas>;
}

export default ProductTypePieChart;
